//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import UiIconButton from 'kolibri.coreVue.components.UiIconButton';

export default {
  name: 'PreviousButton',
  $trs: {
    goToPreviousPage: 'Go to previous page',
  },
  components: {
    UiIconButton,
  },
  props: {
    color: {
      type: String,
      required: true,
      validator(val) {
        return ['black', 'white'].includes(val);
      },
    },
    isRtl: {
      type: Boolean,
      required: true,
    },
  },
};

