//
//
//
//
//
//
//
//
//
//
//
//
//


import KCircularLoader from 'kolibri.coreVue.components.KCircularLoader';

export default {
  name: 'LoadingScreen',
  $trs: {
    loadingBook: 'Loading book',
  },
  components: {
    KCircularLoader,
  },
};

