//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import themeMixin from 'kolibri.coreVue.mixins.themeMixin';

import KGrid from 'kolibri.coreVue.components.KGrid';
import KGridItem from 'kolibri.coreVue.components.KGridItem';
import isEqual from 'lodash/isEqual';
import KButton from 'kolibri.coreVue.components.KButton';
import { darken } from 'kolibri.utils.colour';
import { THEMES } from './EpubConstants';
import SideBar from './SideBar';

export default {
  name: 'SettingsSideBar',
  $trs: {
    textSize: 'Text size',
    decrease: 'Decrease',
    increase: 'Increase',
    theme: 'Theme',
    setWhiteTheme: 'Set white theme',
    setBeigeTheme: 'Set beige theme',
    setGreyTheme: 'Set grey theme',
    setBlackTheme: 'Set black theme',
  },
  components: {
    SideBar,
    KGrid,
    KGridItem,
    KButton,
  },
  mixins: [themeMixin],
  props: {
    theme: {
      type: Object,
      required: true,
      validator(val) {
        return Object.values(THEMES).some(obj => isEqual(obj, val));
      },
    },
    decreaseFontSizeDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    increaseFontSizeDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    themes() {
      return THEMES;
    },
    settingsButtonFocus() {
      return {
        ':focus': {
          ...this.$coreOutline,
          outlineOffset: '0px',
          outlineWidth: '2px',
        },
      };
    },
  },
  methods: {
    generateThemeAriaLabel(themeName) {
      switch (themeName) {
        case 'WHITE':
          return this.$tr('setWhiteTheme');
        case 'BEIGE':
          return this.$tr('setBeigeTheme');
        case 'GREY':
          return this.$tr('setGreyTheme');
        case 'BLACK':
          return this.$tr('setBlackTheme');
        default:
          return '';
      }
    },
    isCurrentlySelectedTheme(theme) {
      return (
        theme.backgroundColor === this.theme.backgroundColor &&
        theme.textColor === this.theme.textColor
      );
    },
    generateStyle(backgroundColor) {
      return {
        ...this.settingsButtonFocus,
        backgroundColor,
        ':hover': {
          backgroundColor: darken(backgroundColor, '10%'),
        },
      };
    },
  },
};

