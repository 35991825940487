//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import KButton from 'kolibri.coreVue.components.KButton';

export default {
  name: 'TableOfContentsSection',
  components: {
    KButton,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    depth: {
      type: Number,
      required: true,
    },
    currentSection: {
      type: Object,
      required: false,
    },
  },
  computed: {
    isCurrentSection() {
      if (this.currentSection) {
        return (
          this.currentSection.href === this.section.href &&
          this.currentSection.label === this.section.label
        );
      }
      return false;
    },
    sectionId() {
      const sanitizedHref = this.section.href.replace(/\W/g, '_');
      return `section_${sanitizedHref}`;
    },
  },
  methods: {
    emitTocNavigation(section) {
      this.$emit('tocNavigation', section);
    },
  },
};

