import { render, staticRenderFns } from "./PreviousButton.vue?vue&type=template&id=6cb92af2&scoped=true&"
import script from "./PreviousButton.vue?vue&type=script&lang=js&"
export * from "./PreviousButton.vue?vue&type=script&lang=js&"
import style0 from "./PreviousButton.vue?vue&type=style&index=0&id=6cb92af2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cb92af2",
  null
  
)

component.options.__file = "PreviousButton.vue"
export default component.exports