//
//
//
//
//
//
//
//
//
//
//
//


import UiAlert from 'kolibri.coreVue.components.UiAlert';

export default {
  name: 'LoadingError',
  $trs: {
    couldNotLoadThisBook: 'Could not load this book',
  },
  components: {
    UiAlert,
  },
};

