//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import themeMixin from 'kolibri.coreVue.mixins.themeMixin';
import KGrid from 'kolibri.coreVue.components.KGrid';
import KGridItem from 'kolibri.coreVue.components.KGridItem';
import UiIconButton from 'kolibri.coreVue.components.UiIconButton';
import TocButton from './TocButton';
import SettingsButton from './SettingsButton';
import SearchButton from './SearchButton';

export default {
  name: 'TopBar',
  $trs: {
    toggleFullscreen: 'Toggle fullscreen',
  },
  components: {
    KGrid,
    KGridItem,
    UiIconButton,
    TocButton,
    SettingsButton,
    SearchButton,
  },
  mixins: [themeMixin],
  props: {
    title: {
      type: String,
      required: false,
    },
    isInFullscreen: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    focusOnTocButton() {
      this.$refs.tocButton.$el.focus();
    },
    focusOnSettingsButton() {
      this.$refs.settingsButton.$el.focus();
    },
    focusOnSearchButton() {
      this.$refs.searchButton.$el.focus();
    },
  },
};

