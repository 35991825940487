//
//
//
//
//
//
//
//
//
//
//
//
//
//


import UiIconButton from 'kolibri.coreVue.components.UiIconButton';

export default {
  name: 'SearchButton',
  $trs: {
    toggleSearchSideBar: 'Toggle search side bar',
  },
  components: {
    UiIconButton,
  },
};

